import {
  displayThousandsOnly,
  getDrawerPopCount,
  getNumFormatter,
  JobSearchEasySteps,
  localStorageSetItem,
} from '@components/common';
import { _CONSTANTS } from '@components/common/constant';
import { Button, Popup } from '@license-admin/boldfjcomponents';
import {
  wizardDrawerPopupClickedEvent,
  wizardDrawerPopupViewedEvent,
} from '@utils/eventTrack';
import { getExperimentVariant } from 'helper/experiments/experimentActions';
import { RMCPortalExperiments } from 'helper/experiments/experimentConstants';
import { useEffect, useRef } from 'react';
import {
  BtnWrapper,
  CloseBtn,
  EasyStepsListItems,
  EasyStepsListWrapper,
  Heading,
  JobSearchStepsWrapper,
  StepNumber,
  StepsContentWrapper,
  StepsHeading,
  StepsSubHeading,
  SubHeading,
} from './styled';

type Props = {
  isCloseAllowed?: boolean;
  showJobSearchSteps?: boolean;
  setJobSearchStepsState: Function;
  signupAndFind?: string;
  mobileView?: boolean;
  categoryDetails?: any;
  jobsData?: any;
  hiringCount?: any;
  setShouldShowSearchStepDrawer: Function;
  screenName: string;
  isModalDrawerOpen?: boolean;
  isCategoryPage?: boolean;
};

export default function JobSearchSteps(props: Readonly<Props>) {
  const {
    categoryDetails,
    jobsData,
    hiringCount,
    signupAndFind,
    screenName,
    isCategoryPage,
  } = props;
  const numFormat = getNumFormatter();
  const jobsCount = hiringCount?.jobsCount ?? 0;
  const formattedCount = numFormat.format(displayThousandsOnly(jobsCount));
  const jobSearchStepsPopupRef = useRef<HTMLDivElement | null>(null);

  const JobSearchEasyStep = JobSearchEasySteps(formattedCount);

  useEffect(() => {
    wizardDrawerPopupViewedEvent(screenName);
  }, []);

  const handleCloseModal = () => {
    const variant = getExperimentVariant(
      props?.mobileView
        ? RMCPortalExperiments.rmcDrawerMobile.id
        : RMCPortalExperiments.rmcDrawerDesktop.id
    );
    wizardDrawerPopupClickedEvent(screenName, 'close');
    props.isCloseAllowed && props.setJobSearchStepsState(false);
    const popCount = getDrawerPopCount();
    variant < 3
      ? localStorageSetItem(_CONSTANTS.JOB_SEARCH_STEPS_POPUP_DISPLAYED, `6`)
      : localStorageSetItem(
          _CONSTANTS.JOB_SEARCH_STEPS_POPUP_DISPLAYED,
          `${popCount}`
        );

    props.setShouldShowSearchStepDrawer(false);
  };

  const handleNext = () => {
    localStorageSetItem('shownSearchStepDrawer', 'true');
    wizardDrawerPopupClickedEvent(screenName, 'visit wizard');
    window.location.href = `/wizard/welcome`;
  };
  return (
    <Popup
      modalSize="lgModalPlus"
      hideCloseModal
      closeModal={handleCloseModal}
      popupId="jobsearch-reg-popup-content"
      heading="login"
      popUpRef={jobSearchStepsPopupRef}
      isSecondaryModal
      showFullWidth={true}
      isModalDrawerOpen={props.isModalDrawerOpen}
    >
      <CloseBtn onClick={() => handleCloseModal()}>&times;</CloseBtn>
      <JobSearchStepsWrapper>
        <Heading>Personalize your Remote Job Search in 3 Easy Steps</Heading>

        <SubHeading>
          Featuring{' '}
          {isCategoryPage
            ? jobsData?.jobs?.totalCount?.toLocaleString()
            : props?.hiringCount?.jobsCount?.toLocaleString()}{' '}
          Jobs {isCategoryPage ? `in ${categoryDetails.name}` : ''}
        </SubHeading>
        <EasyStepsListWrapper>
          {JobSearchEasyStep.map((item: any) => {
            const { id, heading, subHeading } = item;
            return (
              <EasyStepsListItems key={id}>
                <StepNumber>{id}</StepNumber>
                <StepsContentWrapper>
                  <StepsHeading>{heading}</StepsHeading>
                  <StepsSubHeading>{subHeading}</StepsSubHeading>
                </StepsContentWrapper>
              </EasyStepsListItems>
            );
          })}
        </EasyStepsListWrapper>
        <BtnWrapper>
          <Button
            text={signupAndFind}
            buttonType="tertiary"
            fullLength
            buttonSize="md"
            ButtonClass="signup-btn"
            clickHandler={handleNext}
          />
        </BtnWrapper>
      </JobSearchStepsWrapper>
    </Popup>
  );
}
