export const fireExperimentEvent = (
  experimentName,
  variationName,
  experimentID
) => {
  if (!experimentID) return;
  const readCookie = (name) => {
    const nameEQ = `${name}=`;
    try {
      const ca = document.cookie.split(';');
      for (const element of ca) {
        let c = element;
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
          return c.substring(nameEQ.length, c.length);
      }
    } catch (error) {
      return null;
    }
    return null;
  };

  const identify = {};
  identify[`Experiment: ${experimentName}`] = `v${variationName}`;

  const userStatusCookieVal = JSON.parse(readCookie('UserStatus'));
  if (userStatusCookieVal) {
    identify.userId = userStatusCookieVal.User.UserId;
  }

  const visitIdCookie = readCookie('vsuid');
  if (visitIdCookie) {
    identify.visitId = visitIdCookie;
  }

  let item = localStorage.getItem('lp_cdn_conducted_experiments');
  let experiments = JSON.parse(item);
  experiments = experiments || {};
  let expObj = experiments[experimentID] ? experiments[experimentID] : {};

  const expInterval = setInterval(() => {
    if (typeof analytics !== 'undefined') {
      clearInterval(expInterval);

      if (!expObj.isExperimentEventLogged) {
        const variantName =
          variationName === 1 ? 'Baseline' : `${variationName}`;

        if (window.TrackEvents) {
          setTimeout(() => {
            const obj = {
              'experiment id': experimentID,
              'experiment name': experimentName,
              'experiment variant': variantName,
            };

            window.TrackEvents('FJ Experiment Viewed', obj);
            window.TrackEvents('identify', identify, null, null, false);
          }, 0);
        }

        if (experiments[experimentID]) {
          experiments[experimentID].isExperimentEventLogged = true;
        } else {
          experiments[experimentID] = { isExperimentEventLogged: true };
        }

        localStorage.setItem(
          'lp_cdn_conducted_experiments',
          JSON.stringify(experiments)
        );
      }
    }
  }, 100);
};
