import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';
const blob_path = process.env.NEXT_PUBLIC_CONTENT_URL;
export const JobSearchStepsWrapper = styled.div`
  max-width: 129.3rem;
  margin: 4rem auto 8rem;
  padding: 0 2.4rem;
  ${device.mobileLg} {
    margin: 4rem auto;
  }
`;
export const CloseBtn = styled.button`
  ${Mixin.Position({ value: '1.6rem 1.6rem auto auto' })};
  padding: 0;
  cursor: pointer;
  font-size: 2.7rem;
  z-index: 1;
  line-height: 1;
`;
export const Heading = styled.h1`
  text-align: center;
  ${device.mobileLg} {
    ${({ theme: { JobSearchStepsPopupStyle } }) =>
    JobSearchStepsPopupStyle &&
    css`
        font-size: ${JobSearchStepsPopupStyle.FontSizes.heading};
      `}
  }
`;
export const SubHeading = styled.p`
  text-align: center;
  font-weight: 700;
  margin-bottom: 4rem;
  ${({ theme: { JobSearchStepsPopupStyle } }) =>
    JobSearchStepsPopupStyle &&
    css`
      font-size: ${JobSearchStepsPopupStyle.FontSizes.subHeading};
    `}
  ${device.mobileLg} {
    margin-bottom: 2.4rem;
    ${({ theme: { JobSearchStepsPopupStyle } }) =>
    JobSearchStepsPopupStyle &&
    css`
        font-size: ${JobSearchStepsPopupStyle.FontSizes.subMobileHeading};
      `}
  }
`;
export const EasyStepsList = styled.ul``;
export const EasyStepsListItems = styled.div`
  ${Mixin.AllCenter({ align: 'center', justify: 'start' })}
`;
export const StepsContentWrapper = styled.div`
  margin-left: 1.8rem;
  ${device.mobileLg} {
    margin-left: 1rem;
  }
`;
export const BtnWrapper = styled.div`
  max-width: 29.2rem;
  margin: auto;
  ${device.mobileLg} {
    width: 100%;
    max-width: 100%;
  }
`;
export const EasyStepsListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 39.1rem);
  justify-content: space-between;
  gap: 6rem;
  margin-bottom: 5.6rem;
  @media screen and (min-width: 768px) and (max-width:1199px){
    grid-template-columns: repeat(3, 1fr);
  }
  ${device.mobileLg} {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 0.8rem;
    max-width: 28rem;
    margin: 0 auto 3.2rem;
  }
`;
export const StepNumber = styled.span`
  height: 4rem;
  width: 4rem;
  ${Mixin.AllCenter({ align: 'center', justify: 'center' })}
  ${({ theme: { JobSearchStepsPopupStyle } }) =>
    JobSearchStepsPopupStyle &&
    css`
      background-color: ${JobSearchStepsPopupStyle.BgColors.stepNumber};
      font-size: ${JobSearchStepsPopupStyle.FontSizes.stepNumber};
    `}
  font-weight:700;
  border-radius: 50%;
  ${device.mobileLg} {
    height: 2.4rem;
    width: 2.4rem;
    ${({ theme: { JobSearchStepsPopupStyle } }) =>
    JobSearchStepsPopupStyle &&
    css`
      font-size: ${JobSearchStepsPopupStyle.FontSizes.stepMobileNumber};
    `}
  }
`;
export const StepsHeading = styled.p`
  ${({ theme: { JobSearchStepsPopupStyle } }) =>
    JobSearchStepsPopupStyle &&
    css`
      font-size: ${JobSearchStepsPopupStyle.FontSizes.stepsHeading};
    `}
  ${device.mobileLg} {
    ${({ theme: { JobSearchStepsPopupStyle } }) =>
    JobSearchStepsPopupStyle &&
    css`
        font-size: ${JobSearchStepsPopupStyle.FontSizes.stepsMobileHeading};
      `}
  }
  margin-bottom: 0;
  font-weight: 700;
`;
export const StepsSubHeading = styled.span`
  ${({ theme: { JobSearchStepsPopupStyle } }) =>
    JobSearchStepsPopupStyle &&
    css`
      font-size: ${JobSearchStepsPopupStyle.FontSizes.StepsSubHeading};
    `}
  ${device.mobileLg} {
    ${({ theme: { JobSearchStepsPopupStyle } }) =>
    JobSearchStepsPopupStyle &&
    css`
        font-size: ${JobSearchStepsPopupStyle.FontSizes.StepsSubMobileHeading};
      `}
  }
`;
